.truncate-text {
    display: block;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.legafrik-confirm-header{
    display: flex;
    flex-direction: row;
    align-items: center;

}
.legafrik-confirm-header .legafrik-confirm-header-icon{
    width: 40px;
    height: 40px;
}

.legafrik-confirm-header-box-title .legafrik-confirm-header-title{
    margin: 5px 5px 5px 15px;
}
.legafrik-confirm-body-item{
    margin:0.5rem 5px;
}
.legafrik-confirm-body-item .legafrik-confirm-body-item-title,.legafrik-confirm-body-item .legafrik-confirm-body-item-info{
    font-size: 15px;

}

.legafrik-confirm-body-item .legafrik-confirm-body-item-info{
    color: #00614a;
}

.legafrik-confirm-body-item .legafrik-confirm-body-item-title{
    margin-right: 0.5rem;
    color: #000;
}
.legafrik-confirm-footer-content{
    display:flex;
    align-items: center;
    justify-content: center;
}
.legafrik-confirm-footer-item{
    margin:10px;
    width: 100%;
}
.legafrik-confirm-btn-cancel,.legafrik-confirm-btn-send{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius:6px;
    text-decoration: none;
    padding: 0 30px;
    border: 0;
    transition: all 0.3s ease;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    font-size: 14px;
}

.legafrik-confirm-btn-send{
    color: #fff;
    background-color: #00614a;
}
.legafrik-confirm-btn-cancel{
   background-color:#eee;
}
.payment-flex-row{
    display:flex;
    flex-direction: row;
}
.payment-flex-left{
    flex:0 0 65%;
}
.payment-flex-right{
    flex:0 0 34.7%;
    margin: 0 10px;
}
.invoice-surround{
    height: inherit;
    margin: 5px auto;
    width: 100%;

    background: #fff;
    padding: 15px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}
.invoice-header{
    text-align: center;
    margin-bottom: 1rem;
}
.invoice-header .invoice-header-title{
    font-size: 18px;
    font-weight: bold;
}
.invoice-amount-total-box{
    margin-bottom: 1rem;
}
.invoice-amount-total{
    font-size: 16px;
}
.invoice-amount-total .invoice-amount-total-price{
    font-weight: bold;
    color: #00614a;
    margin-left: 5px;
}
.invoice-list{
    display: flex;
    flex-direction: column;
}
.item-text{
    font-weight: bold;
}
.invoice-list .invoice-list-item{
    list-style: none;
    background:#eee;
    margin: 5px 3px;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
}
.invoice-list-item-text{
    margin-right: 5px;
}
.invoice-list-item-description{
    background:#00614a;
    color:#fff;
    padding:4px 8px;
    border-radius: 20px;
}
.invoice-list-item-box{
    margin:3px 2px;
}

.invoice-remainder-paid-box{
    margin:15px 5px;
}

.invoice-remainder-paid{
    font-size: 16px;
}
.invoice-remainder-paid-price{
    margin-left: 5px;
    color: #00614a;
}

.invoice-create-btn-box{
    text-align: center;
}

.z-indexer{
    z-index: 1000!important;
    position: relative;
}


/*Modal*/

.flex-content,.flex-content-column{
    display: flex;
    padding:4px;
}
.flex-content{
    flex-wrap: wrap;
    align-items: center;
}

.flex-content-column{
    flex-direction: column;
}
.flex-item,.flex-item-fex{
    flex: 0 0 48.5%;
    margin: 5px;
    padding: .4rem;
    font-size: 16px;
}
.flex-content-item-body,.flex-content-item-body-wrapper-mapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.flex-content-item-body-fex{
    display: flex;
    flex-direction: column;
    border: 1px solid #00614a;
    padding:5px  10px;
    border-radius: 6px;
    margin: 1rem auto;
}

.item-body-wrapper-header{
    margin: 15px auto;
}
.item-body-wrapper-header-title{
    color:#00614a;
    font-size: 16px;
    text-decoration: underline;
}

.flex-content-item-header h3{
    color:#00614a;
    font-size: 16px;
    font-weight: bold;
}

.flex-item-text,.flex-item-fex-text{
    font-weight: bold;
    margin-right: 4px;
}
.flex-item-status{
    background-color: #f89a1f;
    padding: 5px 10px;
    color: #fff;
    border-radius: 20px;
    font-size: 14px;
}
.flex-btn-end-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-btn-end-item{
    margin:6px;
}
.flex-btn-end-edit{
    background: #cecece!important;
    border: 1px solid #ccc!important;
    color: #000!important;
}

.legafrik-step-wrapper{
    width: 97%;
    margin: 15px auto;
}
.legafrik-step-header{
    text-align: center;
    margin:1rem auto;
    background: #00614a;
    padding: 5px 10px;
    border-radius: 20px;
}

.legafrik-step-header-with-65{
   width: 50%;
}
.legafrik-step-header-title{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

.add-flex-container{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}
.legafrik-documents-content-wrapper{
    margin-top: 10px;
}
.legafrik-documents-content-wrapper-header{
    margin-left: 20px;
}
.legafrik-documents-content-wrapper-header .legafrik-documents-content-wrapper-title{
    font-size: 16px;
    font-weight: bold;
    color: #00614a;
}

.legafrik-documents-content{
    display: flex;
    flex-direction: column;
    width: 65%;
}
.legafrik-additional-documents-box{
    margin-top: 3rem;
    width: 60%;
    text-align: center;
}

.legafrik-documents-content .legafrik-documents-item{
    background:#eee;
    margin: 5px 8px;
    padding:15px;
    border-radius:20px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    cursor:pointer;
}
.legafrik-documents-item .legafrik-documents-name{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 6px;
}
.legafrik-documents-item .legafrik-documents-file{
    width:50px;
}
.legafrik-documents-box-file{
    margin-left: 15px;
    display: flex;
    align-items:center;
}
.legafrik-documents-trash-box{
    background:red;
    padding: 10px;
    border-radius:50%;
    cursor: pointer;
    margin-left: 1rem;
}
.legafrik-documents-trash-icon {
  color: #fff;
}

.legafrik-additional-btn{
    padding: 12px;
    border-radius:50%;
    cursor: pointer;
    margin-left: 1rem;
    background: #00614a;
    color: #fff;
}
.legafrik-additional-text-box{
    margin-top: 10px;
}
.legafrik-additional-text{
    font-size:15px;
}
.legafrik-next-page-btn-box{
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
}
.legafrik-documents-form{
    padding: 10px;
}
.legafrik-documents-form-input-group{
    display: flex;
    flex-direction: column;
    background: #eee;
    padding: 10px 15px;
    border-radius: 15px;
    margin: 8px;
    width: 60%;
}
.legafrik-documents-form-label{
    font-size: 16px;
    color: #000;
    font-weight: bold;
}
.legafrik-documents-form-input{
    padding: 10px 0;
}
.bg-grey{
    background:#eee!important;
}
.legafrik-documents-list-content{
    display:flex;
    justify-content: flex-start;
    margin-top: 2rem;
    flex-wrap: wrap;
}
.legafrik-documents-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #eee;
    padding:15px;
    flex:0 0 18.8%;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
}
.legafrik-documents-list-box-image{
    margin-bottom: 0.3rem;
}
.legafrik-documents-list-box-text{
    text-align: center;
}
.legafrik-documents-list-text{
    font-size:15px;
    font-weight:bold;
}
.legafrik-documents-list-btn-next{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.legafrik-documents-list-btn-next-item{
    margin:1rem 5px;
}

.legafrik-documents-content{
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.legafrik-documents-content .legafrik-documents-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #eee;
    padding:15px;
    flex:0 0 20%;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
}
.legafrik-documents-item .legafrik-documents-box-info{
    display: flex;
    align-items: center;
    margin: 10px 2px;
}
.legafrik-documents-header .legafrik-documents-header-title{
    font-size: 16px;
    color: #000;
    font-weight: bold;
}
.legafrik-documents-box-info-icon{
    margin-right: 10px;
}

.legafrik-documents-box-info-icon .legafrik-documents-info-icon{
    color:#00614a;
}
.legafrik-documents-box-info-name .legafrik-documents-info-name{
    text-decoration: underline;
    font-size: 16px;
}
.legafrik-documents-content-description{
    margin-right: 10px;
}

.box-delete-piece-image{
  text-align: center;
}
.box-delete-piece-image .delete-piece-image{
    width: 120px;
    margin: 10px auto;
}
.box-delete-piece-description{
    text-align: center;
    margin: 1rem 0;
}
.delete-piece-description{
    font-size: 16px;
    font-weight: bold;
    color: red;
}
.compt-piece-description{
    font-size: 16px;
    font-weight: bold;
    color:#00614a;
}
.legafrik-documents-action-item{

}

.legafrik-documents-action-item .legafrik-documents-action-item-btn-edit,
.legafrik-documents-action-item .legafrik-documents-action-item-btn-delete
{
    padding: 8px 10px;
    margin: 5px;
    border-radius: 6px;
}

.legafrik-documents-action-item .legafrik-documents-action-item-btn-edit{
    background-color: rgba(0, 102, 153, 0.5);
}
.legafrik-documents-action-item .legafrik-documents-action-item-btn-delete{
    background-color:red;
}
.legafrik-documents-action-item-btn-icon{
    color: #fff;
}

.legafrik-form-deliver-wrapper{
    width: 60%;
    margin: 10px auto;
}
.legafrik-step-signature-content{
    flex-direction: row;
    display: flex;
}
.legafrik-step-signature-item:first-of-type{
  flex:0 0 70%;
}
.legafrik-box-courier{
    border-radius: 10px;
    border: 1px solid #eee;
    padding: 10px;
    margin-top: 35%;
    width: inherit;
}
.legafrik-courier-image-box{
    text-align: center;
}
.legafrik-courier-image{
    width:100px;
    margin: 10px auto;
}
.legafrik-courier-info-box{
    margin: 10px auto;
}
.legafrik-courier-info,.legafrik-courier-post{
    font-size: 15px;
}
.legafrik-courier-info-name,.legafrik-courier-post-name{
    font-weight: bold;
    margin-right: 5px;
}

.legafrik-rdv-container{
   border:1px solid #eee;
    padding: 10px 15px;
    border-radius:10px;
    margin: 10px 1rem;
}
.legafrik-rdv-header{
    margin-top: 1rem;
}
.legafrik-rdv-header .legafrik-rdv-title{
    font-size: 19px;
}
.legafrik-rdv-header .legafrik-rdv-title-medium{
    font-size: 16px;
}

.legafrik-rdv-title-client{
    font-weight: bold;
    color:#00614a;
}

.legafrik-rdv-date-wrapper{
    margin: 0.5rem;
}
.legafrik-rdv-date{
    font-size: 24px;
}
.legafrik-rdv-date .legafrik-rdv-date-text{
    color:#00614a;
}

.legafrik-rdv-flex-content{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
}
.legafrik-rdv-flex-item{
    flex: 0 0 45%;
    margin: 5px;
    background-color: rgba(0, 102, 153, 0.09);
    border-radius:20px;
    padding: 10px;
}
.legafrik-rdv-flex-com{
    background:#00614a;
    padding: 8px 5px;
    border-radius:10px;
}
.legafrik-rdv-flex-com-text{
    color:#fff;
    font-size: 16px;
}

.legafrik-rdv-flex-box-image{
    margin-top: 1rem;

}
.legafrik-rdv-flex-image{
    width:70px;
    height: 70px;
    margin: 10px auto;
    border-radius:50%;
    border:1px solid #00614a;
}
.legafrik-rdv-flex-box-info{
    padding: 10px;
}
.legafrik-rdv-flex-info-name{
    font-size: 15px;
    line-height: 1.5;
}

.legafrik-rdv-flex-info-name .legafrik-rdv-flex-name-label{
    font-weight: bold;
    margin-right: 5px;
}

.legafrik-rdv-info-content{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.legafrik-rdv-info-content .legafrik-rdv-info-item{
    padding: 5px 1px;
}
.legafrik-rdv-info-item .legafrik-rdv-info-label{
    font-size: 16px;
}
.legafrik-rdv-info-label .legafrik-rdv-info-label-bold{
    font-weight: bold;
}
.invoice-surround-wrappex{
    width: 80%;
    margin:10px auto;
    border: 1px solid #cecece;
    border-radius: 20px;
}
.m1{
    margin:5px;
}

.warning-payment-content{
    display:flex;
    align-items:center;
    background-color: rgba(239, 83, 80, 0.3);
    border-radius: 20px;
    width: 80%;
    margin:10px auto;
}
.warning-payment-box-icon{
    padding: 10px;
}
.warning-payment-box-icon .warning-payment-icon{
   width: 50px;
}
.warning-payment-box-text .warning-payment-text{
    font-size: 16px;
    font-weight: bold;
}
.legafrik-rdv-flex{
    display: flex;
    justify-content: flex-end;
}
.d-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loading-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.legafrik-wrapper-content{
    width: 83%;
    margin:10px auto;
}

.remaining-text,.remaining-amount{
    font-size: 16px;
}
.remaining-amount{
    font-weight: bold;
    color:#006699;
}

/*Pour le tableau*/
.legal-table-not-found{
   text-align: center;
   margin:2rem auto;
}

.legal-table-not-found .legal-text{
    font-size: 16px;
}


.container {
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
   /* min-height: 100vh;*/
    margin: 2rem auto;
}
.legal-table {
    width: 100%;
    border: 1px solid #eee;
}
.legal-table-header {
    display: flex;
    width: 100%;
    background: rgba(0, 102, 153, 0.9);
    padding: 10px 0;
}
.legal-table-row {
    display: flex;
    width: 100%;
    padding: 14px 0;
}
.legal-table-row:nth-of-type(odd) {
    background: #eee;
}
.table-data, .header__item {
    flex: 1 1 20%;
    text-align: center;
}
.disabled  {
    cursor: not-allowed;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);
  }
.header__item {
    text-transform: uppercase;
}
.filter__link {
    color: white;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-left: 24px;
    padding-right: 24px;
}
.filter__link::after {
    content: '';
    position: absolute;
    right: -18px;
    color: white;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
}
.filter__link.desc::after {
    content: '(desc)';
}
.filter__link.asc::after {
    content: '(asc)';
}

/*Specific btn*/


.legal-button {
  background-color: #F15017;
  border: 1px solid #F15017;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 15px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
 }

.legal-button:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
}
.input:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
  
  .input[type=radio] {
    width: 16px;
    height: 16px;
    position: relative;
    border-radius: 9999px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .input[type=radio]:before {
    content: "";
    width: 10px;
    height: 10px;
    transition: all 0.2s ease-in-out;
    --bg-opacity: 1;
    background-color: #1C3FAA;
    background-color: rgba(28, 63, 170, var(--bg-opacity));
    position: absolute;
    border-radius: 9999px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
  }
  
  .input[type=radio]:checked {
    --border-opacity: 1;
    border-color: #1C3FAA;
    border-color: rgba(28, 63, 170, var(--border-opacity));
  }
  
  .input[type=radio]:checked:before {
    opacity: 1;
  }
@media (min-width: 768px) {
    .legal-button {
        padding: 7px 30px;
    }
}
